import { mapGetters } from 'vuex'
import request from '../axios_instance'

export const fetch_services = {
    data: () => ({
        items: [],
        selected_items: [],
        activeItem: null,
        next_page_url: null,
        filters: null,
        view: 'list',
        total_item_counts: 0,
        componentKey: 0,
        /*statuses*/
        loading: false,
        loading_items: false,
        loading_more_items: false,
        button_loading: false,
        submitting: false,
        /*dialogs*/
        add_dialog: false,
        edit_dialog: false,
        add_edit_dialog: false,
        view_dialog: false,
        confirm_dialog: false,
        delete_dialog: false,
        bulk_delete_dialog: false
    }),
    computed: {
        ...mapGetters(['user']),
        has_more_items() {
            return !!this.next_page_url
        },
        selected_items_id() {
            return this.selected_items.length > 0 ?
                this.selected_items.map((i) => {
                    return i.id
                }) :
                []
        }
    },
    methods: {
        loadItems(url, paginated, then_cb, final_cb, err_cb) {
            this.loading_items = true
            let has_pagination = typeof paginated === 'undefined' ? true : paginated
            request
                .get(url)
                .then(({ data }) => {
                    if (has_pagination) {
                        this.items = data.data
                        this.next_page_url = data.next_page_url
                        this.total_item_counts = data.total
                    } else {
                        this.items = data
                        this.next_page_url = null
                        this.total_item_counts = data.length
                    }
                    this.$nextTick(() => {
                        if (typeof then_cb === 'function') then_cb(data)
                    })
                })
                .catch((err) => {
                    if (typeof err_cb === 'function') {
                        err_cb(err)
                    }
                    throw err
                })
                .finally(() => {
                    this.loading_items = false
                    this.$event.$emit('btnloading_off')
                    if (typeof final_cb === 'function') final_cb()
                })
        },
        loadMoreItems(then_cb, final_cb, err_cb) {
            this.loading_more_items = true
            request
                .get(this.next_page_url)
                .then(({ data }) => {
                    this.items.push(...data.data)
                    this.next_page_url = data.next_page_url
                    this.$nextTick(() => {
                        if (typeof then_cb === 'function') then_cb(data)
                    })
                })
                .catch((err) => {
                    if (typeof err_cb === 'function') {
                        err_cb(err)
                    }
                    throw err
                })
                .finally(() => {
                    this.loading_more_items = false
                    this.$event.$emit('btnloading_off')
                    if (typeof final_cb === 'function') final_cb()
                })
        },
        addItem(url, payload, message, then_cb, final_cb, err_cb) {
            this.submitting = true
            request
                .post(url, payload)
                .then(({ data }) => {
                    this.items.push(data)
                    this.$nextTick(() => {
                        if (typeof then_cb === 'function') then_cb(data)
                    })
                    this.appSnackbar(message || 'New item added!')
                })
                .catch((err) => {
                    if (typeof err_cb === 'function') {
                        err_cb(err)
                    }
                    throw err
                })
                .finally(() => {
                    this.submitting = false
                    this.$event.$emit('btnloading_off')
                    if (typeof final_cb === 'function') final_cb()
                })
        },
        addItems(url, payload, message, then_cb, final_cb, err_cb) {
            this.submitting = true
            request
                .post(url, payload)
                .then(({ data }) => {
                    this.items.push(...data)
                    this.$nextTick(() => {
                        if (typeof then_cb === 'function') then_cb(data)
                    })
                    this.appSnackbar(message || 'New items added!')
                })
                .catch((err) => {
                    if (typeof err_cb === 'function') {
                        err_cb(err)
                    }
                    throw err
                })
                .finally(() => {
                    this.submitting = false
                    this.$event.$emit('btnloading_off')
                    if (typeof final_cb === 'function') final_cb()
                })
        },
        updateItem(url, payload, message, then_cb, final_cb, err_cb) {
            this.submitting = true
            request
                .put(url, payload)
                .then(({ data }) => {
                    let index = this.items.findIndex((item) => item.id === data.id)
                    if (~index) {
                        this.items.splice(index, 1, data)
                        this.$nextTick(() => {
                            this.activeItem = data
                        })
                    }
                    this.appSnackbar(message || 'Item updated!')
                    if (typeof then_cb === 'function') then_cb(data)
                })
                .catch((err) => {
                    if (typeof err_cb === 'function') {
                        err_cb(err)
                    }
                    throw err
                })
                .finally(() => {
                    this.submitting = false
                    this.$event.$emit('btnloading_off')
                    if (typeof final_cb === 'function') final_cb()
                })
        },
        replaceItem(newData, message, cb) {
            let findIndex = this.items.findIndex((item) => item.id === newData.id)
            if (~findIndex) {
                this.items.splice(findIndex, 1, newData)
                this.$nextTick(() => {
                    this.activeItem = newData
                    if (typeof then_cb === 'function') cb(newData)
                })
                this.appSnackbar(message || 'Item updated!')
            }
        },
        removeItem(item_id, cb) {
            let findIndex = this.items.findIndex((item) => item.id === item_id)
            if (~findIndex) {
                this.items.splice(findIndex, 1)
                if (typeof then_cb === 'function') cb(item_id)
            }
        },
        insertItem(item, cb) {
            this.items.push(item)
            if (typeof then_cb === 'function') cb(this.items)
        },
        deleteItem(url, message, then_cb, final_cb) {
            this.submitting = true
            request
                .delete(url)
                .then(({ data }) => {
                    let index = this.items.findIndex(
                        (item) => item.id === parseInt(data.id)
                    )
                    if (~index) {
                        this.items.splice(index, 1)
                        this.activeItem = null
                        this.appSnackbar(message || 'Item deleted!')
                    }
                    if (typeof then_cb === 'function') then_cb(data)
                })
                .finally(() => {
                    this.submitting = false
                    this.$event.$emit('btnloading_off')
                    if (typeof final_cb === 'function') final_cb()
                })
        },
        bulkDeleteItems(url, message, then_cb, final_cb) {
            let payload = {
                ids: this.selected_items_id
            }
            this.submitting = true
            request
                .delete(url, { data: payload })
                .then(({ data }) => {
                    this.selected_items.forEach((item) => {
                        let index = this.items.findIndex(
                            (data_item) => data_item.id === item.id
                        )
                        if (~index) this.items.splice(index, 1)
                    })
                    this.appSnackbar(message || 'Items deleted!')
                    if (typeof then_cb === 'function') then_cb(data)
                })
                .finally(() => {
                    this.submitting = false
                    this.$event.$emit('btnloading_off')
                    if (typeof final_cb === 'function') final_cb()
                })
        },
        action_add_dialog(open) {
            this.add_dialog = open
        },
        action_edit_dialog(open, item) {
            this.activeItem = item
            this.edit_dialog = open
        },
        action_add_edit_dialog(open, item) {
            this.activeItem = item
            this.add_edit_dialog = open
        },
        action_view_dialog(open, item) {
            this.activeItem = item
            this.view_dialog = open
        },
        action_confirm_dialog(open, item) {
            this.activeItem = item
            this.confirm_dialog = open
        },
        action_delete_dialog(open) {
            this.delete_dialog = open
        },
        action_bulk_delete_dialog(open, items) {
            this.bulk_delete_dialog = open
            this.selected_items = items
        }
    }
}